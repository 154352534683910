import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';
import addQuery from '../../utils/addQuery'

const HeaderWithImage = ({page}) => {

	return (
		<ContentContainer xsPadding={'30px 20px'} padding={'80px 0'}>
			<Main>
				<MainGetRate>
					<div className="header" dangerouslySetInnerHTML={{
						__html: page.headerWithColor?.childMarkdownRemark.html,
					}}/>
					<div className="subHeader" dangerouslySetInnerHTML={{
						__html: page.subheader?.childMarkdownRemark.html,
					}}/>
					<h1>{page.h1}</h1>
					<div className="content" dangerouslySetInnerHTML={{
						__html: page.heroContent?.childMarkdownRemark.html,
					}}/>

					<div className="btnWrapper">
						<HomeButton
							height={BUTTON_SIZE.HEIGHT_L}
							padding={BUTTON_SIZE.PADDING_L}
							fontSize={BUTTON_SIZE.FONT_SIZE_L}
							background={BUTTON_COLOR.GREEN}
							backgroundHover={BUTTON_COLOR.GREEN_HOVER}
							color={BUTTON_COLOR.TEXT_COLOR_WHITE}
							text='Get My Rate'
							handleClick={() => addQuery(URL.QUOTE)}
							margin='0'
						/>
					</div>
				</MainGetRate>
				<MainImg>
					{/* {page.heroImg ?
						<GatsbyImage image={page.heroImg?.gatsbyImageData} alt={page.heroImg?.title}/>
					: */}
						<StaticImage src='../../assets/images/man-phone.png' alt="manWithPhone" placeholder='none' loading='eager' width={500} height={500}/>
					{/* } */}
				</MainImg>
			</Main>
		</ContentContainer>
	)
}

const Main = styled.div`
	position: relative;
	z-index: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 16px;
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		justify-items: center;
	};
	@media screen and (max-width: 480px) {
		margin: 0;
	};
`
const MainGetRate = styled.div`
	max-width: 586px;
	a {
		&:hover {
			color: var(--base-navy);
		}
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};
	h1 {
		margin: 10px 0;
		font-size: 32px;
		font-family: QuicksandBold;
		font-weight: 1000;

		@media screen and (max-width: 991px) {
			font-size: 24px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};
	};
	.content p {
		font-family: Quicksand;
		margin: 10px 0;
		font-size: 16px;
		font-weight: 200;
		@media screen and (max-width: 991px) {
			font-size: 16px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};
	};
	.subHeader {
		p {
			font-size: 32px;
			font-family: PoppinsBold;
			@media screen and (max-width: 768px) {
				font-size: 24px;
				margin: 20px auto;

			}

		}
		max-width: 450px;
		@media screen and (max-width: 768px) {
			text-align: center;
			margin-right: auto;
			margin-left: auto;
		};
	};
	.header {
		p {
			font-size: 64px;
			font-family: PoppinsBold;
			line-height: 1.2;
			@media screen and (max-width: 768px) {
				font-size: 42px;
				margin: 0 auto;
			}
		}
		max-width: 550px;
		@media screen and (max-width: 768px) {
			text-align: center;
			margin: 0 auto;
		};
	};

	.btnWrapper {
		padding: 40px 0;
		button {
			margin: 0;
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			button {
			margin: 0 auto;
			}
		}
		@media screen and (max-width: 480px) {
			padding: 15px 0 40px 0;
	};

	}
`

const MainImg = styled.div`
	justify-self: center;
	img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 768px) {
		margin: 64px 0 30px 0;
		width: 60vw;
		height: 60vw;
	}
	@media screen and (max-width: 480px) {
		width: 80vw;
		height: 80vw;
		margin: 0px 0 0px 0;
	};
`

export default HeaderWithImage
