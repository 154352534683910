export const reviewRatingMme = {
  "@type":"Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "SocietyOne",
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4.7"
      },
      "author": {
        "@type": "Organization",
        "name": "Google"
      }
    },
  },
  "reviewRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "ratingCount": "30"
  },
  "author": {
    "@type": "Organization",
    "name": "Google"
  }
}

