import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import ContentContainer from '../UI/ContentContainer'


const PageContent = ({page}) => {

	return (
		<ContentContainer>
			<Main>
				<MainGetRate>
					<div className="content" dangerouslySetInnerHTML={{
						__html: page.pageContent?.childMarkdownRemark.html,
					}}/>
				</MainGetRate>
				{/* <MainImg>
					{page.pageImg ? 
						<GatsbyImage image={page.pageImg?.gatsbyImageData} alt={page.pageImg?.title}/>
					:
						<StaticImage src='../../assets/images/Frame-2544.jpg' alt="Phone" placeholder='none' loading='eager' />
					}
				</MainImg> */}
			</Main>
		</ContentContainer>
	)
}

const Main = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	@media screen and (max-width: 768px) {
		${'' /* justify-items: center; */}
	};
	@media screen and (max-width: 480px) {
		flex-direction: column;
		align-items: center;

		margin: 0;
	};
`
const MainGetRate = styled.div`
	a {
		text-decoration: underline;
		color: rgb(29 188 110);
	}
	width: 100%;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};

	.content {
		h2 {
			margin-bottom: 20px;
		}
		p {
			font-size: 20px;
			@media screen and (max-width: 768px) {
				font-size: 18px;
			}
		}
		width: 100%;
		@media screen and (max-width: 768px) {
			margin-right: auto;
			margin-left: auto;
		};
	};

`

const MainImg = styled.div`
	justify-self: center;
	position: sticky;
	top: 90px;
	max-width: 500px;
	img {
		${'' /* border-radius: 50%; */}
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 768px) {
		margin: 64px 0 30px 0;
		width: 60vw;
		height: 60vw;
	}
	@media screen and (max-width: 480px) {
		width: 80vw;
		height: 80vw;
	};
`

export default PageContent
