import parse from 'html-react-parser'; 

export const getContentFromTags = (content) => {
  const v = parse(content) //parse markdown to html tag array
  const filteredValues = v.filter((value) => !!value.type === true) //filter out empty values
  const filteredH3 = v.filter((value, index, self) => value.type === 'h3') //filter out h3 tags
  const h3Index = filteredH3.map(h3 => h3.key) //get key of h3 tags
  const h3Arr = filteredH3.map(h3 => {
    return {
      "h3": h3.props.children,
    }
  }) //get h3 tags and key as an array of objects
  const filteredP = h3Index.map(el => {
    for (let i = 0; i < filteredValues.length; i++) { //loop through filteredValues for the first p tag after each h3 tag
      if (filteredValues[i].key === (Number(el)+2).toString()) {
        if (Array.isArray(filteredValues[i].props.children)) {
          const pArr = filteredValues[i].props.children.filter(p => typeof p === 'string')

          return {
            "p": pArr.join(', '),
          } // if the p tag is an array, then join the array as a string
        }
        return {
          "p": filteredValues[i].props.children,
        } // if the p tag is not an array, get the p next to the h3 from filteredValues
      }
    }
  })
  const faqs = h3Arr.map((h3, index) => {
    return {...h3, ...filteredP[index]}
  }) //combine h3 and p tags as an array of objects with same order
  return faqs
}
